import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiContainer,
  UiFormControl,
  UiFormHelperText,
  UiGrid,
  UiImage,
  UiInput,
  UiSelect,
  UiText,
  UiTextarea,
  UiTitle,
} from '~/components/elements';
import * as Scroll from 'react-scroll';
import {COUPON, PRODUCT_DOMAINS, PRODUCT_TYPES} from '~/constants/coupon';
import {AppDispatch} from '~/redux/root-store';
import {useInput, useNotification, useRequestState} from '~/hooks';
import {useDispatch} from 'react-redux';
import {addCoupon} from '~/redux/coupons/thunk';
import {CouponEntity} from '~/types/models/coupon';
import {requestOK} from 'redux-thunk-kit';

let Element = Scroll.Element;

interface InputParams extends Partial<CouponEntity> {}

const getDefaultParams = (item?: Partial<CouponEntity> | undefined): InputParams => ({
  first_name: item?.first_name || '',
  last_name: item?.last_name || '',
  job_title: item?.job_title || '',
  company: item?.company || '',
  email: item?.email || '',
  phone: item?.phone || '',
  product_domain: item?.product_domain || '',
  product_type: item?.product_type || '',
  message: item?.message || '',
});

const getPostParams = (inputs: InputParams) => ({
  first_name: inputs?.first_name,
  last_name: inputs?.last_name,
  job_title: inputs?.job_title,
  company: inputs?.company,
  email: inputs?.email,
  phone: inputs?.phone,
  product_domain: inputs?.product_domain,
  product_type: inputs?.product_type,
  message: inputs?.message,
});

const FormCoupon = () => {
  const dispatch: AppDispatch = useDispatch();
  const {addMessage} = useNotification(dispatch);
  const {loading} = useRequestState(addCoupon);
  const rules = {
    email: {email: {message: '^Please enter a valid email address'}, presence: {message: '^This field is required'}},
    first_name: {presence: {message: '^This field is required'}},
    last_name: {presence: {message: '^This field is required'}},
    job_title: {presence: {message: '^This field is required'}},
    phone: {presence: {message: '^This field is required'}},
    product_domain: {presence: {message: '^This field is required'}},
    product_type: {presence: {message: '^This field is required'}},
  };
  const defaultParams = getDefaultParams({});
  const {inputs, setInputs, validation, resetInput} = useInput(defaultParams, {rules});

  const onClickSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();
    let resAction;

    if (validation.getErrors()) {
      return;
    }
    const postParams = getPostParams(inputs);
    resAction = await dispatch(addCoupon({data: postParams}));
    if (requestOK(resAction, addCoupon)) {
      resetInput();
      addMessage('Thanks for contacting us. Your request has been submitted successfully!');
    }
  };

  return (
    <Element name={COUPON.FORM}>
      <UiBox
        bg=" linear-gradient(169.69deg, rgba(251, 140, 0, 0.1) 16.69%, rgba(251, 140, 0, 0.3) 43.96%, rgba(251, 140, 0, 0.6) 68.15%, rgba(251, 140, 0, 0.8) 90.42%);"
        p={{base: '50px 0px', lg: '150px 0px'}}
        mt="20px"
        pos="relative"
      >
        <UiBox>
          <UiImage filePath="coupon/balloons.png" w={{base: '3.5em', lg: '6.5em'}} pos="absolute" right="5%" top="1%" />
          <UiImage filePath="coupon/balloons.png" h="2.125em" pos="absolute" left={{base: '1%', lg: '5%'}} top="27%" />
          <UiImage filePath="coupon/tri.png" h="2.125em" pos="absolute" right={{base: '2%', lg: '8%'}} top="45%" />
        </UiBox>
        <UiContainer h="100%" maxW="container.xl" w={{base: '85%', lg: '76%'}} mr="auto" ml="auto">
          <Col alignItems="center">
            <Col>
              <UiTitle fontSize={{base: '3xl', lg: '9xl'}} textAlign="center">
                Get Your Coupon
              </UiTitle>
              <UiText color="black.100" fontSize="xl">
                Try our top-notch services <strong>for free!</strong>
                <br /> No commitment is required, hassle-free redemption process and get a team
                <br /> to dedicate end-to-end responsibility to your projects!
              </UiText>
            </Col>
            <UiBox
              borderRadius={{base: '12px', lg: '30px'}}
              backgroundColor="white"
              p={{base: '30px 10px', lg: '35px 20px'}}
              w={{base: '100%', lg: '80%'}}
              mt={{base: '30px', lg: '50px'}}
            >
              <Col>
                <UiGrid
                  templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
                  columnGap={{base: 3, lg: 10}}
                  rowGap={3}
                >
                  <UiFormControl>
                    <UiInput
                      placeholder="First Name*"
                      value={inputs.first_name}
                      onChange={({target}) => setInputs({first_name: target.value})}
                      fontSize="sm"
                      borderRadius="8px"
                    />
                    {validation?.errors?.first_name && (
                      <UiFormHelperText mt="8px" color="red" fontSize="13px">
                        {validation.errors.first_name[0]}
                      </UiFormHelperText>
                    )}
                  </UiFormControl>
                  <UiFormControl>
                    <UiInput
                      placeholder="Last Name*"
                      value={inputs.last_name}
                      onChange={({target}) => setInputs({last_name: target.value})}
                      fontSize="sm"
                      borderRadius="8px"
                    />
                    {validation?.errors?.last_name && (
                      <UiFormHelperText mt="8px" color="red" fontSize="13px">
                        {validation.errors.last_name[0]}
                      </UiFormHelperText>
                    )}
                  </UiFormControl>
                  <UiFormControl>
                    <UiInput
                      placeholder="Email*"
                      value={inputs.email}
                      onChange={({target}) => setInputs({email: target.value})}
                      fontSize="sm"
                      borderRadius="8px"
                    />
                    {validation?.errors?.email && (
                      <UiFormHelperText mt="8px" color="red" fontSize="13px">
                        {validation.errors.email[0]}
                      </UiFormHelperText>
                    )}
                  </UiFormControl>
                  <UiFormControl>
                    <UiInput
                      placeholder="Phone Number*"
                      value={inputs.phone}
                      onChange={({target}) => setInputs({phone: target.value})}
                      fontSize="sm"
                      borderRadius="8px"
                    />
                    {validation?.errors?.phone && (
                      <UiFormHelperText mt="8px" color="red" fontSize="13px">
                        {validation.errors.phone[0]}
                      </UiFormHelperText>
                    )}
                  </UiFormControl>
                  <UiFormControl>
                    <UiInput
                      placeholder="Company"
                      value={inputs.company}
                      onChange={({target}) => setInputs({company: target.value})}
                      fontSize="sm"
                      borderRadius="8px"
                    />
                  </UiFormControl>
                  <UiFormControl>
                    <UiInput
                      placeholder="Job Title*"
                      value={inputs.job_title}
                      onChange={({target}) => setInputs({job_title: target.value})}
                      fontSize="sm"
                      borderRadius="8px"
                    />
                  </UiFormControl>
                </UiGrid>
                <UiFormControl>
                  <UiSelect
                    placeholder="Which is your product's domain?*"
                    fontSize="sm"
                    borderRadius="8px"
                    mt="20px"
                    value={inputs.product_domain}
                    onChange={e => setInputs({product_domain: e.target.value})}
                  >
                    {PRODUCT_DOMAINS.map((value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    })}
                  </UiSelect>
                  {validation?.errors?.product_domain && (
                    <UiFormHelperText mt="8px" color="red" fontSize="13px">
                      {validation.errors.product_domain[0]}
                    </UiFormHelperText>
                  )}
                </UiFormControl>
                <UiFormControl>
                  <UiSelect
                    placeholder="Which type of product you are aiming for?*"
                    fontSize="sm"
                    borderRadius="8px"
                    mt="20px"
                    value={inputs.product_type}
                    onChange={e => setInputs({product_type: e.target.value})}
                  >
                    {PRODUCT_TYPES.map((value, index) => {
                      return (
                        <option value={value} key={index}>
                          {value}
                        </option>
                      );
                    })}
                  </UiSelect>
                  {validation?.errors?.product_type && (
                    <UiFormHelperText mt="8px" color="red" fontSize="13px">
                      {validation.errors.product_type[0]}
                    </UiFormHelperText>
                  )}
                </UiFormControl>
                <UiTextarea
                  placeholder="Write us a message"
                  borderRadius="20px"
                  fontSize="sm"
                  mt="20px"
                  rows={6}
                  value={inputs.message}
                  onChange={e => setInputs({message: e.target.value})}
                />
                <Row justifyContent="center" mt="45px" w="100%">
                  <UiButton isLoading={loading} padding="7px 100px" onClick={onClickSubmit}>
                    Submit
                  </UiButton>
                </Row>
              </Col>
            </UiBox>
          </Col>
        </UiContainer>
      </UiBox>
    </Element>
  );
};

export default FormCoupon;

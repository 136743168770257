import React from 'react';
import useMetaTags from 'react-metatags-hook';
import {UiBox} from '~/components/elements';
import Banner from '~/pages/coupon/components/Banner';
import QualityCostTime from '~/pages/coupon/components/QualityCostTime';
import HowWeMakeIt from '~/pages/coupon/components/HowWeMakeIt';
import HowItWork from '~/pages/coupon/components/HowItWork';
import FormCoupon from '~/pages/coupon/components/FormCoupon';
import ContactUsTime from '~/components/shared/ContactUsTime';
import {ContactUs} from '~/components/shared/ContactUs';
import ContactUsScrumTeam from '~/components/shared/ContactUsScrumTeam';
import TalkToUs from '~/pages/coupon/components/TalkToUs';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const CouponPage = () => {
  useScrollToTop();

  useMetaTags(
    {
      title: `Coupon | Rayyone`,
      description: `"Try our top-notch services for free!
      No commitment is required, hassle-free redemption process, 
      2 weeks of free dedicated end-to-end responsibility to your projects!"`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content:
            'build mvp for free,create app for free,build app,build web,app development for free,software development for free,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox pb="30px">
      <Banner />
      <QualityCostTime />
      <HowWeMakeIt />
      <HowItWork />
      <FormCoupon />
      <TalkToUs />
      <ContactUsTime />
      <UiBox mt="100px" />
      <ContactUsScrumTeam />
      <ContactUs />
    </UiBox>
  );
};

export default CouponPage;

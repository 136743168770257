import React from 'react';
import {Row, UiBox, UiButton, UiText, UiTitle} from '~/components/elements';
import {CALENDLY_LINK} from '~/constants/coupon';

const TalkToUs = () => {
  const onClick = () => {
    return window.open(CALENDLY_LINK, '_blank', 'noopener,noreferrer');
  };

  return (
    <UiBox textAlign="center" mt={{base: '50px', lg: '130px'}} padding={{base: '0px 10px', lg: '0px'}}>
      <UiTitle fontSize={{base: 'xl', lg: '5xl'}}>Not sure where to start? Talk to us!</UiTitle>
      <UiText fontSize="xl" mt={{base: '20px', lg: '50px'}}>
        Share with us your story!
        <br />
        Schedule a one-hour meeting with our Senior leadership to look at <br />
        where you are now and where you want to be.
        <br />
        Usually, we can meet within a few days and start work within one to two weeks.
      </UiText>
      <Row justifyContent="center" mt={{base: '20px', lg: '50px'}}>
        <UiButton
          p="20px 70px"
          textDecor="underline"
          bgColor="transparent"
          border="1px solid #FB8C00"
          color="black"
          onClick={onClick}
        >
          Schedule the meeting {'>'}
        </UiButton>
      </Row>
    </UiBox>
  );
};

export default TalkToUs;

import React from 'react';
import {Col, Row, UiBox, UiContainer, UiIcon, UiImage, UiText, UiTitle} from '~/components/elements';
import bg from '~/assets/img/coupon/bg.png';
import {BsDot} from 'react-icons/bs';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import circle from '~/assets/img/coupon/circle.png';
import {ComponentProps} from '~/types/models/app';
import parse from 'html-react-parser';

const content = [
  '<strong>FASTER</strong> speed to market',
  '<strong>CHEAPER</strong> cost of development',
  '<strong>HIGHER QUALITY</strong> of final products and infrastructures',
];

const Content = (p: ComponentProps) => {
  return (
    <Row mb={{base: '5px', lg: '25px'}} alignItems="center">
      <UiIcon as={BsDot} width="25px" height="25px" color="white" />
      <UiText fontSize={{base: 'sm', lg: '2xl'}} {...p} />
    </Row>
  );
};

const QualityCostTime = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox mt={{base: '50px', lg: '150px'}} pb={{base: '50px', lg: '200px'}}>
      <UiContainer h="100%" maxW="container.xl" w={{base: '90%', lg: '76%'}} mr="auto" ml="auto">
        <Col alignItems="center" justifyContent="center" textAlign="center">
          <UiTitle fontSize={{base: '2xl', lg: '5xl'}} pos="relative">
            Quality - Cost - Time: they say
            <UiTitle
              as="span"
              pos="relative"
              backgroundImage={`url(${circle})`}
              backgroundRepeat="no-repeat"
              backgroundSize="100% 100%"
            >
              {' '}
              ‘pick any two’?
            </UiTitle>
          </UiTitle>
          <UiText mt={{base: '25px', lg: '50px'}} fontSize="2xl">
            The traditional project management premise is that there are three main
            {isDesktop && <br />} factors in all decisions, and you can have or control only two of them?
          </UiText>
        </Col>
        {isDesktop && (
          <Row alignItems="center" justifyContent="flex-end" w={{base: '100%', lg: '90%'}} mt="30px">
            <UiImage filePath="coupon/factor-assignment.png" w="35em" />
          </Row>
        )}
      </UiContainer>
      {!isDesktop && (
        <Row w="100%" justifyContent="flex-end">
          <Row alignItems="center" w="90%" mt="30px">
            <UiImage filePath="coupon/factor-assignment.png" w="35em" />
          </Row>
        </Row>
      )}
      <Row
        w="100%"
        justifyContent="flex-end"
        mt={{base: '20px', lg: '100px'}}
        pos="relative"
        h={{base: '13.25em', lg: '31.25em'}}
      >
        <Col
          w={{base: '100%', lg: '80%'}}
          h={{base: '13.25em', lg: '31.25em'}}
          backgroundImage={`url(${bg})`}
          backgroundRepeat="no-repeat"
          backgroundSize="100% 100%"
          pos="absolute"
          zIndex="1"
        />
        <UiImage
          filePath="coupon/cup.png"
          h={{base: '9em', lg: '28em'}}
          pos="absolute"
          zIndex="2"
          left={{base: '2%', lg: '25%'}}
          top={{base: '15%', lg: '3%'}}
        />
        <Col zIndex="4" color="white" w={{base: '70%', lg: '50%'}} justifyContent="center">
          <UiTitle
            textAlign="center"
            fontWeight="500"
            fontSize={{base: 'xl', lg: '5xl'}}
            lineHeight={{base: '25px', lg: '45px'}}
            mt={{base: '20px', lg: '60px'}}
          >
            Come with us
            {isDesktop && <br />}
            where the triple constraints
            {isDesktop && <br />} intertwined
          </UiTitle>
          <Col mt={{base: '5px', lg: '55px'}}>
            {content.map(val => {
              return <Content key={val}>{parse(val)}</Content>;
            })}
          </Col>
        </Col>
      </Row>
    </UiBox>
  );
};

export default QualityCostTime;

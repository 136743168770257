import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiContainer,
  UiGrid,
  UiIcon,
  UiImage,
  UiLink,
  UiText,
  UiTitle,
} from '~/components/elements/index';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {IoIosArrowForward} from 'react-icons/io';
import {QUERY_MOBILE} from '~/constants';
import {useMediaQuery} from '@chakra-ui/react';
import * as Scroll from 'react-scroll';
import {ComponentProps} from '~/types/models/app';
import {COUPON} from '~/constants/coupon';

let Link = Scroll.Link;
let Element = Scroll.Element;

const GUIDES = ['FORM SUBMISSION', 'COUPON SENT', 'GET STARTED', 'EXTRA MILE'];

const StyledImage = ({imageUrl}: {imageUrl: string}) => {
  return (
    <UiBox>
      <Row
        w={{base: '13.5em', lg: '22.5em'}}
        h={{base: '13.5em', lg: '22.5em'}}
        pos="relative"
        alignItems="center"
        justifyContent="center"
      >
        <UiBox
          bg="linear-gradient(190.57deg, rgba(251, 140, 0, 0.8) -16.23%, rgba(255, 255, 255, 0) 112.53%, rgba(251, 140, 0, 0.5) 112.53%)"
          w="100%"
          h="100%"
          pos="absolute"
          borderRadius="50%"
          opacity="0.3"
          transform="rotate(173.46deg)"
        />
        <UiImage filePath={imageUrl} zIndex="100" />
      </Row>
    </UiBox>
  );
};

const HowItWork = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const [step, setStep] = useState<number>(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const sliderRef = useRef<Slider | null>(null);

  useEffect(() => {
    setStep(slideIndex);
    if (sliderRef?.current) {
      sliderRef.current!.slickGoTo(slideIndex);
    }
  }, [slideIndex]);

  const StyledBox = (p: ComponentProps) => {
    return <UiBox as={isDesktop ? Row : Col} alignItems="center" justifyContent="space-between" mt="50px" {...p} />;
  };

  const formSubmission = () => {
    return (
      <UiBox>
        <UiGrid
          templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
          columnGap={{base: 3, lg: 10}}
          rowGap={10}
          mt="30px"
        >
          <Col>
            <UiImage filePath="coupon/form.png" />
          </Col>
          <Col justifyContent="center" h="70%" mt={{base: '10px'}}>
            <UiText fontSize="3xl">
              <strong>The fruitful journey start with a short form!</strong> Claim your 2-week-free coupon by submitting
              the short form.
            </UiText>
          </Col>
        </UiGrid>
      </UiBox>
    );
  };

  const couponSent = () => {
    return (
      <StyledBox>
        <StyledImage imageUrl="coupon/ticket.png" />
        <UiText fontSize="2xl" pl={{lg: '60px'}} mt={{base: '10px'}}>
          <strong>We send you the coupon email!</strong> Coupon will be sent to you on the same or next business day.
          <br /> No commitment is required. As long as the coupon is still valid, you can freely redeem it with us.
        </UiText>
      </StyledBox>
    );
  };

  const getStarted = () => {
    return (
      <StyledBox>
        <StyledImage imageUrl="coupon/calendar.png" />
        <UiText fontSize="2xl" pl={{lg: '60px'}} mt={{base: '10px'}}>
          <strong>Let’s connect!</strong> Once you are ready to kick started the project, just approach us! <br />
          We are available for you at email, phone number, whatsapp and more...
          <br />
          We then jump into a warm up call, clarify all the expectations and deliverables. <br />
          Just that, you successfully kicked started the project with 2-week-free.
          <br />
        </UiText>
      </StyledBox>
    );
  };

  const accompaniment = () => {
    return (
      <StyledBox w="100%">
        <StyledImage imageUrl="coupon/location.png" />
        <Col pl={{lg: '60px'}} mt={{base: '10px'}} alignItems="center" w="100%">
          <UiText fontSize="2xl">
            <strong>We accompany you on the long-run!</strong>
            <br />
            After the 1st Sprint ends, strategic plan for your project further development will be shared over.
            <br />
            <br />
            You can either <strong>go an extra mile</strong> with us or just drop us a warm ‘see you’ email.
          </UiText>
          <Row w={{base: '90%', lg: '100%'}}>
            <Link to={COUPON.FORM} smooth={true} hashSpy={true} offset={0} duration={800}>
              <UiButton w="90%" mt="20px">
                Start your 2-week free now!
              </UiButton>
            </Link>
          </Row>
        </Col>
      </StyledBox>
    );
  };

  const sliderSettings = {
    className: 'slider variable-width',
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplaySpeed: 1000,
    cssEase: 'linear',
  };

  const handleAfterChange = useCallback((slide: number) => {
    setStep(slide);
  }, []);

  return (
    <UiBox mt={{base: '50px', lg: '100px'}}>
      <UiContainer h="100%" maxW="container.xl" w={{base: '85%', lg: '76%'}} mr="auto" ml="auto">
        <Col alignItems="center">
          <Row mb="20px">
            <Link to={COUPON.FORM} smooth={true} hashSpy={true} offset={-70} duration={800}>
              <UiButton>Start my 1st Sprint free</UiButton>
            </Link>
          </Row>
          <Link to={COUPON.HOW_WE_WORK} smooth={true} hashSpy={true} offset={-200} duration={800}>
            <UiLink textDecorationLine="underline" fontSize={{base: 'sm', lg: 'md'}}>
              or learn more about how the coupon works
            </UiLink>
          </Link>

          <UiImage filePath="coupon/arrow.png" mt="20px" />

          <UiTitle mt="10px" fontSize="6xl">
            How it works
          </UiTitle>
          <Row
            mt="20px"
            alignItems="center"
            color="orange.400"
            fontSize={{base: '0.7em', lg: '2xl'}}
            textAlign="center"
          >
            {GUIDES.map((val, idx) => {
              return (
                <Row key={val} alignItems="center" cursor="pointer" onClick={() => setSlideIndex(idx)}>
                  <UiText fontWeight={step === idx ? 'bold' : ''}>{val}</UiText>
                  {idx !== GUIDES.length - 1 && <UiIcon as={IoIosArrowForward} />}
                </Row>
              );
            })}
          </Row>
        </Col>
        <UiBox sx={{minH: '28.125em'}}>
          <Element name={COUPON.HOW_WE_WORK}>
            <Slider ref={sliderRef} afterChange={handleAfterChange} {...sliderSettings}>
              <div>{formSubmission()}</div>
              <div> {couponSent()}</div>
              <div>{getStarted()}</div>
              <div>{accompaniment()}</div>
            </Slider>
          </Element>
        </UiBox>
      </UiContainer>
    </UiBox>
  );
};

export default HowItWork;

import {tab} from '~/constants/story';

export const SOCIAL_LOGIN_PLATFORMS = {
  GOOGLE: 'GOOGLE',
};
export const ERROR_STATUS_CODE = {
  EMAIL_VERIFIED_ERROR: 'alg_wc_ev_email_verified_error',
  INCORRECT_PASSWORD: 'incorrect_password',
  INVALID_EMAIL: 'invalid_email',
};

export const PAGES = {
  SERVICE: '/services',
  EXPAND_TEAM: '/services/expand-your-team',
  BUILD_IDEAS: '/services/build-your-ideas',
  MANAGE_SERVICE: '/services/manage-entire-service',
  HOW_WE_WORK: '/how-we-work',
  COMPANY: '/company',
  POLICY: '/privacy-policy',
  CONTACT_US: '/contact',
  STORIES: '/stories',
  STORIES_ECOMMERCE: `#${tab.E_COMMERCE}`,
  STORIES_MOBILEAPP: `#${tab.MOBILE}`,
  STORIES_LANDING: `#${tab.LANDING_PAGE}`,
  CAREER: '/careers',
  JOB: '/jobs',
  START_UP: '/startups',
  CONTACT_SUBMITTED: '/contact-submitted',
  COUPON: '/coupon',
  RAYYONE_TOOLS: '/rayyone-tools',
  UNSUBSCRIBE: '/unsubscribe',
};

export const QUERY_MOBILE = '62em';
export const QUERY_SMALL_PHONE = '24.374em';

export const PAGES_BANNER_SAME_TEXT_COLOR_NAVIGATION = ['jobs/'];

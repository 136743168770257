import React from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiImage, UiText, UiTitle} from '~/components/elements';
import banner from '~/assets/img/coupon/banner.png';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import * as Scroll from 'react-scroll';
import {COUPON} from '~/constants/coupon';

let Link = Scroll.Link;

const CouponImage = () => (
  <Row alignItems="center" justifyContent="center">
    <Row
      bg="linear-gradient(190.57deg, #FFFFFF -16.23%, rgba(255, 255, 255, 0) 112.53%)"
      opacity="0.3"
      borderRadius="50%"
      w={{base: '20em', lg: '28.75em'}}
      h={{base: '20em', lg: '28.75em'}}
      pos="absolute"
    />
    <UiImage filePath="coupon/coupon.png" h={{base: '22.5em', lg: '29.5em'}} />
  </Row>
);

const Banner = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox
      mb="0px"
      minH="100vh"
      h={{base: 'auto', lg: '100vh'}}
      pt={{base: '30px', lg: 50}}
      pb={{base: '20px'}}
      backgroundImage={
        isDesktop
          ? `url(${banner})`
          : 'linear-gradient(100.73deg, rgba(251, 140, 0, 0.2) 1.11%, rgba(251, 140, 0, 0.4) 36.88%, rgba(251, 140, 0, 0.74) 68.61%, #FB8C00 97.83%)'
      }
      backgroundRepeat="no-repeat"
      backgroundSize="100% 100%"
    >
      <UiContainer h="100%" maxW="container.xl" w={{base: '80%', lg: '76%'}} mr="auto" ml="auto">
        <Col position="relative" h="100%" mt={{base: '20px', lg: '100px'}}>
          {!isDesktop && (
            <Row alignItems="center" justifyContent="center">
              <CouponImage />
            </Row>
          )}
          <UiTitle fontSize={{base: '4xl', lg: '9xl'}} lineHeight={{base: '35px', lg: '90px'}}>
            <UiTitle as="span" color="orange.400">
              Start
            </UiTitle>{' '}
            Your Product
            {isDesktop ? <br /> : ' '} Now!{' '}
            <UiTitle as="span" color="orange.400">
              For Free
            </UiTitle>
          </UiTitle>
          <UiText fontSize="xl" color="#647576" mt={{base: '10px', lg: '50px'}}>
            Try our top-notch services{' '}
            <UiText as="span" fontWeight="bold">
              for free!
            </UiText>
            <br />
            No commitment is required, hassle-free redemption process,{isDesktop ? <br /> : ' '}
            <UiText as="span" fontWeight="bold">
              2 weeks of free
            </UiText>{' '}
            dedicated end-to-end responsibility to your projects!
          </UiText>
          <Col justifyContent="center" alignItems="start" pt={{base: '50px', lg: '50px'}}>
            <Link to={COUPON.FORM} smooth={true} hashSpy={true} offset={-70} duration={800}>
              <UiButton bgColor="orange.400" color="white" boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)" fontSize="md">
                Get my 2-week-free coupon
              </UiButton>
            </Link>
          </Col>
          {isDesktop && (
            <Row pos="absolute" right={{base: '0%', lg: '-10%'}} alignItems="center" justifyContent="center">
              <CouponImage />
            </Row>
          )}

          <UiBox>
            {isDesktop && <UiImage filePath="coupon/balloons.png" w="6.5em" pos="absolute" right="40%" top="50%" />}
            <UiImage
              filePath="coupon/balloons.png"
              w="2.125em"
              pos="absolute"
              left="-5%"
              top={{base: '31.5%', lg: '-3%'}}
            />
            <UiImage
              filePath="coupon/tri.png"
              w="2.125em"
              pos="absolute"
              left={{base: '-15%', lg: '-5%'}}
              bottom={{base: '10%', lg: '45%'}}
            />
            <UiImage
              filePath="coupon/tri.png"
              w="2.125em"
              pos="absolute"
              transform="rotate(105.85deg)"
              right={{base: '0%', lg: '38%'}}
              top={{base: '0%', lg: '-5%'}}
            />
            {isDesktop && <UiImage filePath="coupon/star.png" w="2.125em" pos="absolute" left="35%" top="55%" />}
          </UiBox>
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default Banner;

import {PrivateLayout, PublicLayout} from '~/components/layouts';
import {BasicRoute, PrivateRoute} from '~/components/elements/AppRouter';
import NotFoundPage from './pages/not-found';
import ServicesPage from '~/pages/services/page/index';
import ExpandTeamsPage from '~/pages/expand-your-team';
import BuildYourIdeas from '~/pages/build-your-ideas';
import MangeEntireServicePage from '~/pages/manage-entire-service';
import {PAGES} from '~/constants';
import HowWeWorkPage from '~/pages/how-we-work';
import PrivacyPolicyPage from '~/pages/privacy-policy';
import ContactUsPage from '~/pages/contact-us/page/index';
import StoryPage from '~/pages/story/page/index';
import CompanyPage from '~/pages/company';
import StartupPage from '~/pages/start-up/page/index';
import HomePage from '~/pages/home-page/page/index';
import CareerPage from '~/pages/career/page/index';
import JobsPage from '~/pages/jobs/index';
import JobDetailPage from '~/pages/job-detail/page/index';
import ContactSubmitted from '~/pages/contact-submitted/index';
import CouponPage from '~/pages/coupon/page/index';
import RayyoneTools from '~/pages/rayyone-tools/page/index';
import UnsubscribePage from '~/pages/unsubscribe';

export const publicRoutes: BasicRoute[] = [
  {
    path: '/',
    component: PublicLayout,
    exact: false,
    routes: [
      {path: '/', component: HomePage, exact: true},
      {
        path: PAGES.SERVICE,
        label: 'Services',
        component: ServicesPage,
        exact: true,
      },
      {
        path: PAGES.EXPAND_TEAM,
        exact: true,
        component: ExpandTeamsPage,
      },
      {
        path: PAGES.BUILD_IDEAS,
        exact: true,
        component: BuildYourIdeas,
      },
      {
        path: PAGES.MANAGE_SERVICE,
        exact: true,
        component: MangeEntireServicePage,
      },
      {
        path: PAGES.HOW_WE_WORK,
        label: 'How we work',
        exact: true,
        component: HowWeWorkPage,
      },
      {
        path: PAGES.COMPANY,
        label: 'Company',
        exact: true,
        component: CompanyPage,
      },
      {
        path: PAGES.STORIES,
        label: 'Stories',
        exact: true,
        component: StoryPage,
      },
      {
        path: PAGES.START_UP,
        label: 'Startups',
        exact: true,
        component: StartupPage,
      },
      {
        path: PAGES.CAREER,
        label: 'Career',
        exact: true,
        component: CareerPage,
      },
      {
        path: PAGES.JOB,
        exact: true,
        component: JobsPage,
      },
      {
        path: `${PAGES.JOB}/:id`,
        exact: true,
        component: JobDetailPage,
      },
      {
        path: PAGES.RAYYONE_TOOLS,
        label: 'Products',
        exact: true,
        component: RayyoneTools,
      },
      {
        path: PAGES.COUPON,
        label: 'Coupon',
        exact: true,
        component: CouponPage,
      },
      {
        path: PAGES.CONTACT_US,
        exact: true,
        component: ContactUsPage,
      },
      {
        path: PAGES.POLICY,
        exact: true,
        component: PrivacyPolicyPage,
      },
      {
        path: `${PAGES.UNSUBSCRIBE}`,
        exact: true,
        component: UnsubscribePage,
      },
      {path: PAGES.CONTACT_SUBMITTED, exact: true, component: ContactSubmitted},
    ],
  },
];

export const privateRoutes: PrivateRoute[] = [
  {
    path: '/i',
    component: PrivateLayout,
    exact: false,
    routes: [],
  },
];
